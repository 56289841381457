
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { AxiosRequestConfig } from "axios";
import { VueEditor } from "vue3-editor";
import { event } from "vue-gtag";
import JwtService from "@/core/services/JwtService";

interface Answer {
  id?: number;
  qId?: number;
  text: string;
  isCorrect: boolean;
}

interface Question {
  id?: number;
  text: string;
  answers: Answer[];
}

interface Exercise {
  _id: string;
  title: string;
  module: string;
  subject: string;
  createdAt: string;
  updatedAt: string;
  deadline?: string;
  marked: boolean;
  desc: string;
  files: string[];
  trimester: string;
  students: [];
  classrooms: string[];
  status: string;
  reviewDate: string;
  reviewNote: string;
  questions: Question[];
}

interface Comment {
  _id: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  photo: string;
  text: string;
  user: { _id: string; role: string };
}

export default defineComponent({
  name: "exercise-details",
  components: {
    VueEditor,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const user = store.getters.currentUser;
    const fullName = user.employee.firstName + " " + user.employee.lastName;

    const status = ref("notValidated");
    const reason = ref("");

    const comments = ref<Comment[]>([]);

    const homeworkID = route.params.id;

    const exercise = ref<Exercise>({
      _id: "",
      subject: "",
      title: "",
      module: "",
      desc: "",
      createdAt: "",
      updatedAt: "",
      deadline: "",
      marked: false,
      files: [],
      trimester: "",
      students: [],
      classrooms: [],
      status: "",
      reviewDate: "",
      reviewNote: "",
      questions: [{ text: "", answers: [{ text: "", isCorrect: false }] }],
    });

    interface ClassRoom {
      _id: string;
      name: string;
    }

    const studentList = ref<[]>([]);
    const getStudents = (students: [], classrooms: string[]) => {
      const sy = window.localStorage.getItem("activeSchoolarYear");
      const match = {};
      match[`schoolarYearsHistory.${sy}`] = {
        $in: classrooms,
      };
      ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $project: {
              _id: 1,
              firstName: 1,
              lastName: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          studentList.value = data.filter((s) =>
            students.find((id) => id == s._id)
          );
        })
        .catch((e) => console.log(e));
    };

    ApiService.get(`/lms/quiz/${homeworkID}`)
      .then(({ data }) => {
        exercise.value = {
          subject: data.subject.name,
          _id: data._id,
          title: data.title,
          module: data.module,
          desc: data.desc,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          deadline: data.deadline,
          marked: data.marked,
          files: data.resources,
          trimester: data.trimester ? data.trimester : "0",
          students: data.students,
          classrooms: data.classrooms,
          status: data.status,
          reviewDate: data.reviewDate,
          reviewNote: data.reviewNote,
          questions: data.questions,
        };
        if (data.students && data.students.length != 0)
          getStudents(data.students, data.classrooms);
        getClassrooms(data.classrooms);
      })
      .catch((e) => {
        console.log(e);
      });

    // ApiService.get(`/lms/comment/homework/${homeworkID}`)
    //   .then(({ data }) => {
    //     data.forEach((comment: Comment) => {
    //       comment.photo = comment.photo
    //         ? store.getters.serverConfigUrl.base_url +
    //           "/" +
    //           comment.photo.replace(/\\/, "/")
    //         : "media/avatars/blank.png";
    //       comments.value.push(comment);
    //     });
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });

    const classRooms = ref<ClassRoom[]>([]);
    const trimester = JwtService.getTrimester();
    function getClassrooms(avaliable: string[]) {
      ApiService.post("/teacher/classRommDetails", {
        query: {
          teacher: store.getters.currentUser._id,
          trimester: trimester,
        },
      })
        .then(({ data }) => {
          for (const classroom of data) {
            if (avaliable.includes(classroom.classRoom._id))
              classRooms.value.push({
                _id: classroom.classRoom._id,
                name: classroom.classRoom.name,
              });
          }
        })
        .catch((e) => console.log(e));
    }

    const saveReview = () => {
      Swal.fire({
        title:
          status.value == "rejected"
            ? t(`course.confirmRejectHomework`)
            : t(`course.confirmValidationHomework`),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: status.value == "rejected" ? "#ff3333" : "#47BE7D",
        cancelButtonColor: "#3085d6",
        confirmButtonText:
          status.value == "rejected"
            ? t(`course.acceptReject`)
            : t(`course.acceptValidate`),
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.patch("/lms/advisor/reviewQuiz/" + exercise.value._id, {
            status: status.value,
            reviewNote: reason.value,
          } as AxiosRequestConfig)
            .then(() => {
              event("Review exercice", {
                event_category: "Exercice",
                event_label: "Exercice section",
                value: 1,
              });
              Swal.fire(
                status.value == "rejected"
                  ? t(`course.rejected`)
                  : t(`course.validated`),
                status.value == "rejected"
                  ? t("course.rejectedHomeworkInfo")
                  : t("course.validatedHomeworkInfo"),
                "success"
              ).then(() => {
                exercise.value.status = status.value;
                exercise.value.reviewDate = new Date().toString();
              });
            })
            .catch((e) => console.log(e));
        }
      });
    };

    return {
      t,
      homeworkID,
      exercise,
      saveReview,
      moment,
      comments,
      fullName,
      studentList,
      classRooms,
      status,
      reason,
    };
  },
});
